<script>
import { GetInvite } from '@/graphql/auth/invite.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  query(props) {
    return {
      query: GetInvite,
      variables: {
        id: props.id
      }
    };
  }
};
</script>
