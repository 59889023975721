<template>
  <div class="page-signup-form flex flex-col items-center my-6">
    <the-page-header centered>
      <template slot="title">
        Create Account
      </template>
      <template slot="subtitle">
        Enter your information to create a flytedesk account.
      </template>
    </the-page-header>

    <div class="w-1/3 border-bottom mb-3 min-w-25 pb-10 text-md">
      <el-form
        ref="signupForm"
        :disabled="isRegistering"
        :rules="rules"
        :model="signupForm"
      >
        <custom-el-form-item
          ref="organizationName"
          label-class="font-bold"
          label="Organization Name"
          prop="name"
        >
          <el-input v-model="signupForm.name" placeholder="Organization Name" />
        </custom-el-form-item>
        <form-create-account
          ref="accountForm"
          v-model="accountForm"
          :is-loading="isRegistering"
        />
      </el-form>

      <supplier-mutation @error="resetForm" @done="onDoneRegistering">
        <template v-slot="{ registerSupplier }">
          <div class="mt-6">
            <el-button
              v-if="!isRegistering"
              class="button-blue w-full"
              @click="submitForm(registerSupplier)"
            >
              Create Account
            </el-button>
            <loading-button v-else class="w-full" />
          </div>
        </template>
      </supplier-mutation>

      <el-alert
        v-if="registrationError"
        type="error"
        :title="registrationError"
        class="mt-4"
        :closable="false"
      />
    </div>

    <div class="text-center text-md">
      <div class="mb-2">
        By creating an account, you agree to our
        <a href="https://flytedesk.com/terms-and-conditions" target="_blank">
          Terms and Conditions
        </a>
      </div>
      <div>
        Have an account already?
        <a :href="`${$apiUrl}/login`">Sign In</a>
      </div>
    </div>
  </div>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import FormCreateAccount from '@/components/Core/Form/FormCreateAccount';
import { LoadingButton } from '@/components/Core/Loading';
import { SupplierMutation } from '@/components/Mutations';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    CustomElFormItem,
    FormCreateAccount,
    LoadingButton,
    SupplierMutation,
    ThePageHeader
  },
  data() {
    return {
      accountForm: {},
      isRegistering: false,
      signupForm: {
        campus_id: this.$store.getters['auth/signupInfo'].campus.id,
        name: ''
      },
      registrationError: null,
      rules: {
        name: [
          {
            required: true,
            message: 'Please input an organization name',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    resetForm(error) {
      this.registrationError =
        error || 'Invalid credentials. Please try again.';
      this.isRegistering = false;

      if (error === 'Campus already has a publication and users.') {
        this.$refs.organizationName.resetField();
      }

      this.$refs.accountForm.resetFields();
    },
    async onDoneRegistering() {
      await this.$store.dispatch('auth/getUser');

      if (this.$store.getters['auth/isAuthenticated']) {
        this.$router.replace({ name: 'supplier.mediakit' });
      } else {
        this.resetForm();
      }
    },
    submitForm(registerSupplier) {
      this.$refs.signupForm.validate(valid => {
        if (valid) {
          this.$refs.accountForm.validate(valid => {
            if (valid) {
              this.isRegistering = true;
              this.registrationError = null;
              registerSupplier({ ...this.signupForm, ...this.accountForm });
            }
          });
        }
      });
    }
  }
};
</script>
