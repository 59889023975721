<template>
  <div class="flex flex-col items-center my-6">
    <the-page-header centered>
      <template slot="title">
        Complete Registration
      </template>
      <template slot="subtitle">
        We need to get just a little information from you before. Enter your
        information below and you'll be all set.
      </template>
    </the-page-header>

    <div class="w-1/3 border-bottom mb-3 min-w-25 pb-10 text-md">
      <user-mutation
        @done="afterRegistering"
        @error="registrationError = $event"
      >
        <template v-slot="{ isSaving, completeRegistration }">
          <form-create-account
            ref="registerForm"
            v-model="registerForm"
            :is-loading="isSaving"
            :default-values="registerData"
            disabled-email
            @submit="submitForm(completeRegistration)"
          >
            <div slot="bottom" class="mt-8">
              <el-button
                v-if="!isSaving"
                class="w-full button-blue"
                @click="submitForm(completeRegistration)"
              >
                Complete Registration
              </el-button>
              <loading-button v-else class="w-full" />
            </div>
          </form-create-account>
        </template>

        <el-alert
          v-if="registrationError"
          type="error"
          :title="registrationError"
          class="mt-4"
          :closable="false"
        />
      </user-mutation>
    </div>

    <div class="text-center text-md">
      <div class="mb-2">
        By creating an account, you agree to our
        <a href="https://flytedesk.com/terms-and-conditions" target="_blank">
          Terms and Conditions
        </a>
      </div>
      <div>
        Have an account already?
        <router-link :to="{ name: 'auth.login' }">Sign In</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import FormCreateAccount from '@/components/Core/Form/FormCreateAccount';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';
import UserMutation from '@/components/Mutations/Core/UserMutation';

export default {
  components: {
    UserMutation,
    FormCreateAccount,
    ThePageHeader
  },
  data() {
    return {
      registerForm: {
        first_name: '',
        last_name: '',
        user_email: '',
        user_phone: ''
      },
      registrationError: null
    };
  },
  computed: {
    registerData() {
      try {
        let data = JSON.parse(atob(this.$route.params.data));

        return {
          ...data,
          user_email: data.email || '',
          user_phone: data.phone || ''
        };
      } catch (error) {
        this.$message.error(
          'Failed to parse user registration data. Please contact us for help.'
        );
        return {};
      }
    },
    mappedRegisterForm() {
      return {
        first_name: this.registerForm.first_name,
        last_name: this.registerForm.last_name,
        phone: this.registerForm.user_phone,
        new_password: this.registerForm.user_password
      };
    }
  },
  methods: {
    async afterRegistering(data) {
      await this.$store.dispatch('auth/getUser');

      if (this.$store.getters['auth/isAuthenticated']) {
        this.registrationError = null;

        this.$message.success(
          'Congratulations! You have successfully registered with Flytedesk!'
        );

        if (this.$store.getters['auth/isSupplier']) {
          this.$router.replace({ name: 'supplier.dashboard' });
        } else {
          this.$router.replace({ name: 'buyer.dashboard' });
        }
      } else {
        this.registrationError =
          data.errors[0] ||
          'There was a problem signing you in. Please try signing in manually or contact us for help';
      }
    },
    submitForm(completeRegistration) {
      this.registrationError = null;

      this.$refs.registerForm.validate(valid => {
        if (valid) {
          completeRegistration(
            this.$route.params.userId,
            this.mappedRegisterForm
          );
        }
      });
    }
  }
};
</script>
