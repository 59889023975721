<template>
  <div class="flex flex-col items-center my-6">
    <the-page-header centered>
      <template slot="title">
        Sign In
      </template>
      <template slot="subtitle">
        Enter your email address and password to sign into your flytedesk
        account
      </template>
    </the-page-header>
    <login-form @authenticated="$router.replace({ path: '/' })" />
    <div class="flex flex-col text-center text-sm">
      <router-link class="mb-3" :to="{ name: 'auth.forgotPassword' }">
        Forgot Password
      </router-link>
      <a @click.prevent="showSignupDialog = true">Create new account</a>
    </div>
    <el-dialog
      append-to-body
      title="Sign Up"
      width="33%"
      :visible.sync="showSignupDialog"
    >
      <div class="text-center">
        Which type of account would you like to Sign Up for?
      </div>
      <div slot="footer" class="flex w-full">
        <router-link
          class="button-white w-1/2"
          :to="{ name: 'auth.findCampus' }"
          tag="el-button"
        >
          Publisher
        </router-link>
        <el-button
          class="button-white w-1/2"
          type="primary"
          @click="goToBuyerSignup"
        >
          Buyer
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LoginForm from '@/components/Auth/LoginForm';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    LoginForm,
    ThePageHeader
  },

  data() {
    return {
      showSignupDialog: false
    };
  },

  methods: {
    goToBuyerSignup() {
      window.location = 'http://www.flytedesk.com/buyer/signup';
    }
  }
};
</script>
