<template>
  <div class="flex flex-col items-center my-6">
    <the-page-header centered>
      <template slot="title">
        {{ config.title }}
      </template>
      <template slot="subtitle">
        {{ config.subTitle }}
      </template>
    </the-page-header>
    <password-mutation
      class="w-1/3 min-w-25"
      @data="isSuccess = true"
      @error="onError"
    >
      <template v-slot="{ loading, sendResetPasswordEmail }">
        <el-form
          v-show="!isSuccess"
          ref="emailForm"
          :rules="rules"
          :model="emailForm"
        >
          <custom-el-form-item prop="email">
            <el-input
              v-model="emailForm.email"
              placeholder="Enter Email"
              @keyup.enter.native="onButtonPress(sendResetPasswordEmail)"
            />
          </custom-el-form-item>
          <el-input v-show="false" />
        </el-form>
        <el-button
          v-if="!loading"
          class="button-blue w-full mt-6"
          @click="onButtonPress(sendResetPasswordEmail)"
        >
          {{ config.buttonTitle }}
        </el-button>
        <loading-button v-else class="w-full mt-6" />
      </template>
    </password-mutation>
  </div>
</template>

<script>
import { validEmail } from '@/utils/filters';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { LoadingButton } from '@/components/Core/Loading';
import { PasswordMutation } from '@/components/Mutations';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    CustomElFormItem,
    LoadingButton,
    PasswordMutation,
    ThePageHeader
  },

  data() {
    return {
      isSuccess: false,
      rules: {
        email: [{ required: true, validator: validEmail, trigger: 'blur' }]
      },
      showSignupDialog: false,
      emailForm: {
        email: ''
      }
    };
  },

  computed: {
    config() {
      return {
        buttonTitle: this.isSuccess ? 'Try again' : 'Submit',
        title: this.isSuccess ? 'Success' : 'Reset Password',
        subTitle: this.isSuccess
          ? 'We’ve emailed you a password reset link. Be sure to check your spam folder if you don’t see it in your inbox.'
          : 'Enter your email address and we will send you a password reset link.'
      };
    }
  },

  methods: {
    onError() {
      this.$refs.emailForm.resetFields();
      this.isSuccess = false;
    },
    onButtonPress(sendResetPasswordEmail) {
      if (this.isSuccess) {
        this.$refs.emailForm.resetFields();
        this.isSuccess = false;
      } else {
        this.$refs.emailForm.validate(valid => {
          if (valid) {
            sendResetPasswordEmail(this.emailForm.email);
          }
        });
      }
    }
  }
};
</script>
