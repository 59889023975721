<template>
  <div class="flex flex-col items-center my-6">
    <the-page-header centered>
      <template slot="title">
        New Password
      </template>
      <template slot="subtitle">
        Enter a new password for your flytedesk account.
      </template>
    </the-page-header>
    <password-mutation
      class="w-1/3 min-w-25"
      @data="$router.replace({ name: 'auth.login' })"
      @error="$refs.passwordForm.resetFields()"
    >
      <template v-slot="{ loading, resetPassword }">
        <el-form
          ref="passwordForm"
          label-position="top"
          :rules="rules"
          :model="passwordForm"
        >
          <custom-el-form-item
            class="mt-4"
            label-class="font-bold"
            label="New Password"
            prop="password"
          >
            <el-input
              v-model="passwordForm.password"
              type="password"
              placeholder="Password"
              @keyup.enter.native="$refs.passwordConfirmation.focus()"
            />
          </custom-el-form-item>
          <custom-el-form-item
            class="mt-4"
            label-class="font-bold"
            label="Confirm New Password"
            prop="password_confirmation"
          >
            <el-input
              ref="passwordConfirmation"
              v-model="passwordForm.password_confirmation"
              type="password"
              placeholder="Confirm Password"
              @keyup.enter.native="onResetPassword(resetPassword)"
            />
          </custom-el-form-item>
        </el-form>
        <el-button
          v-if="!loading"
          class="button-blue w-full mt-6"
          @click="onResetPassword(resetPassword)"
        >
          Change Password
        </el-button>
        <loading-button v-else class="w-full mt-6" />
      </template>
    </password-mutation>
  </div>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { LoadingButton } from '@/components/Core/Loading';
import { PasswordMutation } from '@/components/Mutations';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';

export default {
  components: {
    CustomElFormItem,
    LoadingButton,
    PasswordMutation,
    ThePageHeader
  },
  props: {
    email: { type: String, default: null },
    token: { type: String, default: null }
  },

  data() {
    return {
      rules: {
        password: [
          { required: true, validator: this.validatePassword, trigger: 'blur' }
        ],
        password_confirmation: [
          {
            required: true,
            validator: this.validatePasswordConfirmation,
            trigger: 'change'
          }
        ]
      },
      passwordForm: {
        password: '',
        password_confirmation: ''
      }
    };
  },

  created() {
    if (!this.email || !this.token) {
      this.$message.error({
        message: 'Did you recieve an email to reset your password?',
        showClose: true
      });
      this.$router.replace({ name: 'auth.login' });
    }
  },

  methods: {
    onResetPassword(resetPassword) {
      this.$refs.passwordForm.validate(valid => {
        if (valid && this.token && this.email) {
          resetPassword({
            email: this.email,
            token: this.token,
            ...this.passwordForm
          });
        }
      });
    },
    validatePassword(rule, value, callback) {
      if (!value) {
        callback(new Error('Please input a password'));
      } else if (value.length < 8) {
        callback(
          new Error('Passwords must be at least 8 charecters in length')
        );
      } else {
        callback();
      }
    },
    validatePasswordConfirmation(rule, value, callback) {
      if (!value) {
        callback(new Error('Please input a password'));
      } else if (value !== this.passwordForm.password) {
        callback(new Error('Passwords do not match'));
      } else {
        callback();
      }
    }
  }
};
</script>
