<template>
  <div class="flex flex-col items-center my-6">
    <invite-query :id="inviteId" @data="invite = $event.invite" />

    <!-- Header -->
    <the-page-header centered>
      <template slot="title">
        Join Organization
      </template>
      <template slot="subtitle">
        You've been invited to be a member of {{ organizationName }}
      </template>
    </the-page-header>

    <!-- Form -->
    <user-mutation @done="onDoneRegistering" @error="isRegistering = false">
      <template v-slot="{ loading, createSupplierUser, createBuyerUser }">
        <div class="w-1/3 border-bottom mx-auto mb-3 min-w-25 text-md">
          <template v-if="invite">
            <template v-if="isRejected">
              <div class="py-32 text-lg text-center">
                This invite has been rejected by the admin on the organization
                you're trying to join.

                <div class="mt-3">Please request a new team invite.</div>
              </div>
            </template>
            <template v-else>
              <form-create-account
                ref="accountForm"
                v-model="accountForm"
                :default-email="email"
                disabled-email
                :is-loading="isRegistering"
              />
              <div class="mt-6">
                <el-button
                  v-if="!isRegistering"
                  class="button-blue w-full"
                  @click="submitForm(createSupplierUser, createBuyerUser)"
                >
                  Create Account
                </el-button>
                <loading-button v-else class="w-full" />
              </div>
            </template>
          </template>
          <template v-else>
            <loader-box width="100%" :height="30" />
          </template>
        </div>
      </template>
    </user-mutation>

    <!-- Footer -->
    <div class="text-center text-md">
      <div class="mb-2">
        By creating an account, you agree to our
        <a href="https://flytedesk.com/terms-and-conditions" target="_blank">
          Terms and Conditions
        </a>
      </div>
      <div>
        Have an account already?
        <router-link :to="{ name: 'auth.login' }">Sign In</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import FormCreateAccount from '@/components/Core/Form/FormCreateAccount';
import { LoadingButton } from '@/components/Core/Loading';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';
import { UserMutation } from '@/components/Mutations';
import InviteQuery from '@/components/Queries/Auth/InviteQuery';

export default {
  components: {
    InviteQuery,
    FormCreateAccount,
    LoadingButton,
    ThePageHeader,
    UserMutation
  },
  props: {
    email: { type: String, default: null },
    inviteId: { type: String, default: null },
    organization: { type: String, default: null }
  },
  data() {
    return {
      invite: null,
      accountForm: {},
      isRegistering: false,
      registrationError: null
    };
  },
  computed: {
    isRejected() {
      return this.invite && this.invite.status === 'REJECTED';
    },
    isSupplierSignup() {
      return this.$route.path.match(/\/auth\/supplier/i);
    },
    organizationName() {
      return this.organization || 'this team';
    }
  },
  created() {
    if (!this.inviteId || !this.email) {
      this.$message.error(
        'Invitation was invalid. Please re-request your invitation'
      );
      this.$router.replace({ name: 'auth.login' });
    }
  },
  methods: {
    async onDoneRegistering() {
      await this.$store.dispatch('auth/getUser');

      if (this.$store.getters['auth/isAuthenticated']) {
        await this.$router.replace({
          name: this.isSupplierSignup ? 'supplier.dashboard' : 'buyer.dashboard'
        });
      } else {
        this.isRegistering = false;
      }
    },
    submitForm(createSupplierUser, createBuyerUser) {
      this.$refs.accountForm.validate(async valid => {
        if (valid) {
          this.isRegistering = true;
          if (this.isSupplierSignup) {
            createSupplierUser({
              ...this.accountForm,
              invite_id: this.inviteId
            });
          } else {
            createBuyerUser({
              ...this.accountForm,
              invite_id: this.inviteId
            });
          }
        }
      });
    }
  }
};
</script>
