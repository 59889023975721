<template>
  <el-form
    ref="accountForm"
    :disabled="isDisabled || isLoading"
    :rules="rules"
    :model="accountForm"
    @submit="$emit('submit', $event)"
  >
    <div class="flex flex-row mt-4">
      <custom-el-form-item
        :disabled="isDisabled"
        :is-loading="isLoading"
        label-class="font-bold"
        class="w-1/2 mr-3"
        label="First Name"
        prop="first_name"
      >
        <el-input
          v-model="accountForm.first_name"
          placeholder="First Name"
          @input="$emit('input', accountForm)"
        />
      </custom-el-form-item>
      <custom-el-form-item
        :disabled="isDisabled"
        :is-loading="isLoading"
        label-class="font-bold"
        class="w-1/2"
        label="Last Name"
        prop="last_name"
      >
        <el-input
          v-model="accountForm.last_name"
          placeholder="Last Name"
          @input="$emit('input', accountForm)"
        />
      </custom-el-form-item>
    </div>
    <div class="flex flex-row mt-4">
      <custom-el-form-item
        :disabled="isDisabled"
        :is-loading="isLoading"
        label-class="font-bold"
        class="w-1/2 mr-3"
        label="Email"
        prop="user_email"
      >
        <el-input
          v-model="accountForm.user_email"
          :disabled="disabledEmail"
          placeholder="Email"
          @input="$emit('input', accountForm)"
        />
      </custom-el-form-item>
      <custom-el-form-item
        :disabled="isDisabled"
        :is-loading="isLoading"
        label-class="font-bold"
        class="w-1/2"
        label="Phone Number"
        prop="user_phone"
      >
        <el-input
          v-model="accountForm.user_phone"
          v-mask.phone
          placeholder="Phone Number"
          @input="$emit('input', accountForm)"
        />
      </custom-el-form-item>
    </div>
    <custom-el-form-item
      label-class="font-bold"
      class="mt-4"
      label="Create Password"
      prop="user_password"
    >
      <el-input
        v-model="accountForm.user_password"
        placeholder="Password"
        type="password"
        @input="$emit('input', accountForm)"
      />
    </custom-el-form-item>
    <custom-el-form-item
      label-class="font-bold"
      class="mt-4"
      label="Confirm Password"
      prop="password_confirmation"
    >
      <el-input
        v-model="accountForm.password_confirmation"
        placeholder="Password"
        type="password"
        @input="$emit('input', accountForm)"
      />
    </custom-el-form-item>

    <slot name="bottom" />
  </el-form>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { validEmail, validPassword } from '@/utils/filters';

const defaultRules = { required: true, trigger: 'blur' };

export default {
  components: {
    CustomElFormItem
  },
  props: {
    isLoading: Boolean,
    isDisabled: Boolean,
    defaultEmail: { type: String, default: '' },
    defaultValues: {
      type: Object,
      default: null
    },
    disabledEmail: Boolean
  },
  data() {
    let accountForm = {
      user_email: this.defaultEmail
    };

    if (this.defaultValues) {
      accountForm = {
        ...accountForm,
        ...this.defaultValues
      };
    }

    return {
      accountForm,
      rules: {
        first_name: [{ message: 'First name required', ...defaultRules }],
        last_name: [{ message: 'Last name required', ...defaultRules }],
        user_email: [{ ...defaultRules }],
        user_password: [{ validator: validPassword, ...defaultRules }],
        password_confirmation: [
          {
            ...defaultRules,
            validator: this.validatePasswordConfirmation,
            trigger: 'change'
          }
        ]
      }
    };
  },
  methods: {
    clearValidate() {
      this.$refs.accountForm.clearValidate();
    },
    resetFields() {
      this.$refs.accountForm.resetFields();
    },
    validate(callback) {
      this.$refs.accountForm.validate(callback);
    },
    validatePasswordConfirmation(rule, value, callback) {
      if (!value) {
        callback(new Error('Please input a password'));
      }
      if (value !== this.accountForm.user_password) {
        callback(new Error('Passwords do not match'));
      }
      callback();
    }
  }
};
</script>
