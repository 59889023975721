<template>
  <div class="flex flex-col items-center my-12 text-center">
    <div class="text-md w-1/3 min-w-25 mt-1">
      <template v-if="!hasRequestedAccess">
        <h1>Join Organization</h1>

        <div class="mt-5">
          Looks like your organization already has a flytedesk account. Enter
          your email below and an Admin will review your request to join.
        </div>
        <supplier-team-mutation @data="onAccessRequested">
          <template
            v-slot="{
              loading,
              requestSupplierMemberAccess
            }"
          >
            <el-form
              ref="requestForm"
              class="mt-6 text-left"
              :disabled="loading"
              :rules="rules"
              :model="requestForm"
            >
              <custom-el-form-item
                label-class="font-bold"
                label="Email"
                prop="email"
              >
                <el-input
                  v-model="requestForm.email"
                  placeholder="Email"
                  @keyup.enter.native="sendEmail(requestSupplierMemberAccess)"
                />
              </custom-el-form-item>
              <el-input v-show="false" />

              <el-button
                v-if="!loading"
                class="button-blue w-full mt-6"
                @click="sendEmail(requestSupplierMemberAccess)"
              >
                Request Access
              </el-button>
              <loading-button v-else class="w-full mt-6" />
            </el-form>
          </template>
        </supplier-team-mutation>
      </template>
      <template v-else>
        <h1>Success!</h1>
        <div class="mt-5">
          You've successfully requested access to {{ organizationName }}. An
          account admin will review your request.
        </div>
      </template>
      <div class="border-bottom pb-5 mb-5"></div>
      Please
      <a href="https://flytedesk.com/contact" target="_blank">
        contact us
      </a>
      if you believe the admin of your orgnaziation is outdated or if you
      believe you should be the admin of your orgnaziation.
    </div>
  </div>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { LoadingButton } from '@/components/Core/Loading';
import { SupplierTeamMutation } from '@/components/Mutations';

export default {
  components: {
    CustomElFormItem,
    LoadingButton,
    SupplierTeamMutation
  },
  data() {
    return {
      isSending: false,
      requestForm: {
        email: ''
      },
      rules: {
        email: [
          { required: true, message: 'Please input an Email', trigger: 'blur' }
        ]
      },
      hasRequestedAccess: false
    };
  },
  computed: {
    organizationName() {
      return this.$store.getters['auth/signupInfo']
        ? this.$store.getters['auth/signupInfo'].supplier.name
        : null;
    }
  },
  created() {
    if (!this.$store.getter['auth/signupInfo']) {
      this.$message.error(
        'You need to select an Media organization to join first'
      );
      this.$router.replace({ name: 'auth.findCampus' });
    }
  },
  methods: {
    onAccessRequested() {
      this.hasRequestedAccess = true;
      this.$refs.requestForm.resetFields();
      this.$refs.requestForm.clearValidation();
    },
    sendEmail(requestSupplierMemberAccess) {
      this.$refs.requestForm.validate(valid => {
        if (valid) {
          requestSupplierMemberAccess(this.requestForm.email);
        }
      });
    }
  }
};
</script>
