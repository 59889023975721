<template>
  <the-default-layout>
    <the-account-bar slot="header" :class="config.headerClass">
      <template slot="logo">
        <a :href="config.iconLink" target="_blank">
          <icon :class="config.iconClass" :icon="flytedeskLogo" />
        </a>
      </template>
      <span v-if="isSupplierSignup" slot="title">
        supplier
      </span>
      <div v-if="isPasswordRoute">
        <router-link class="navbar-item" :to="{ name: 'auth.login' }"
          >Sign In
        </router-link>
        <router-link class="navbar-item" :to="{ name: 'auth.findCampus' }"
          >Sign Up
        </router-link>
      </div>
    </the-account-bar>
  </the-default-layout>
</template>

<script>
import { flytedeskLogo } from '@/vendor/icons';

import TheAccountBar from '@/components/Core/Layout/TheAccountBar';
import TheDefaultLayout from '@/components/Core/Layout/TheDefaultLayout';

export default {
  components: {
    TheAccountBar,
    TheDefaultLayout
  },
  data() {
    return {
      flytedeskLogo
    };
  },
  computed: {
    config() {
      return {
        headerClass: this.isSupplierSignup ? 'bg-blue' : '',
        iconClass: this.isSupplierSignup
          ? 'cursor-pointer text-3xl text-white'
          : 'cursor-pointer text-3xl',
        iconLink: this.isSupplierSignup
          ? 'https://www.flytedesk.com/suppliers/overview'
          : 'https://www.flytedesk.com'
      };
    },
    isPasswordRoute() {
      return this.$route.path.match(/\/auth\/.*?password/i);
    },
    isSupplierSignup() {
      return this.$route.path.match(/\/auth\/supplier/i);
    }
  }
};
</script>
