<template>
  <div class="flex flex-col items-center my-6">
    <the-page-header centered>
      <template slot="title">
        Find My Campus
      </template>
      <template slot="subtitle">
        Select your campus to either create a new account or request to join a
        team
      </template>
    </the-page-header>
    <div class="w-1/3 border-bottom mb-3 min-w-25">
      <el-form
        ref="selectedCampus"
        label-position="top"
        :rules="rules"
        :model="selectedCampus"
      >
        <custom-el-form-item prop="name">
          <campuses-query
            :loading.sync="isLoading"
            :sort="{ name: 'ascending' }"
            :supplier-filter="{ is_test: false }"
            :pager="{ perPage: 0 }"
            @data="campuses = $event.data"
          >
            <el-select
              v-model="selectedCampus"
              :loading="isLoading"
              filterable
              class="w-full"
              placeholder="Start typing your campus name"
              value-key="id"
            >
              <el-option
                v-for="campus in campuses"
                :key="`campus-${campus.id}`"
                :label="campus.name"
                :value="campus"
              />
            </el-select>
          </campuses-query>
        </custom-el-form-item>
      </el-form>
      <el-select
        v-if="selectedCampus.suppliers && selectedCampus.suppliers.length"
        v-model="selectedSupplier"
        class="w-full mt-7"
        filterable
        placeholder="Start typing your campus name"
        value-key="id"
      >
        <el-option
          v-for="supplier in selectedCampus.suppliers"
          :key="`supplier-${supplier.uuid}`"
          :label="supplier.name"
          :value="supplier"
        />
        <el-option
          key="supplier-new"
          label=" + Create A New Organization"
          :value="{ id: 'NEW_SUPPLIER' }"
        />
      </el-select>
      <el-button class="button-blue w-full mt-6 mb-12" @click="onSubmit">
        Submit
      </el-button>
    </div>
    <div class="text-center text-md">
      <div class="mb-2">
        Have an account already?
        <router-link :to="{ name: 'auth.login' }">
          Sign In
        </router-link>
      </div>
      <div class="mb-2">
        Can’t find your campus?
        <a href="https://flytedesk.com/contact" target="_blank">
          Contact Us
        </a>
      </div>
      <div>
        Looking to buy ads?
        <a href="https://www.flytedesk.com/buyer/signup">Buyer Sign Up</a>
      </div>
    </div>
  </div>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';
import { CampusesQuery } from '@/components/Queries';

export default {
  components: {
    CustomElFormItem,
    CampusesQuery,
    ThePageHeader
  },
  data() {
    return {
      isLoading: null,
      rules: {
        name: [
          {
            required: true,
            message: 'Please select a campus',
            trigger: 'change'
          }
        ]
      },
      campuses: null,
      selectedSupplier: this.$store.getters['auth/signupInfo']
        ? this.$store.getters['auth/signupInfo'].supplier
        : null,
      selectedCampus: this.$store.getters['auth/signupInfo']
        ? this.$store.getters['auth/signupInfo'].campus
        : {
            name: null,
            suppliers: []
          }
    };
  },
  watch: {
    selectedCampus(newVal) {
      if (newVal.name && newVal.suppliers.length) {
        this.selectedSupplier = newVal.suppliers[0];
      }
    }
  },
  methods: {
    onSubmit() {
      this.$refs.selectedCampus.validate(valid => {
        if (valid) {
          this.$store.commit('auth/updateSignupInfo', {
            campus: this.selectedCampus,
            supplier: this.selectedSupplier
          });

          if (
            this.selectedSupplier &&
            this.selectedSupplier.id !== 'NEW_SUPPLIER'
          ) {
            this.$router.push({
              name: 'auth.joinAccount'
            });
          } else {
            this.$router.push({
              name: 'auth.signupForm'
            });
          }
        }
      });
    }
  }
};
</script>
